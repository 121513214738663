export const component = async () => {
    const name = 'equ-alert';
    const $btnCloseAlert = $('#btnCloseAlert');

    const $alert = $('[data-' + name + ']');

    if ($alert.length > 0) {
        var hours = 24; // Reset when storage is more than 24hours
        var now = new Date().getTime();
        var closedAlert = localStorage.getItem('closedAlert');

        if (now - closedAlert > hours * 60 * 60 * 1000) {
            $alert.removeClass('u-hidden').addClass('is-active');

            $btnCloseAlert.click((e) => {
                $alert.remove();

                localStorage.setItem('closedAlert', now);
            });
        } else {
            $alert.remove();
        }
    }
};
